.container {
  padding: 20px;
  background-color: #068f82;
  width: 100%;
}


/* Mobile */
@media (max-width: 700px) {
  .container {
    padding: 20px;
    background-color: #068f82;
    width: 100%;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
