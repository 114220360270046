.container {
  padding: 20px;
  background-color: #bb0b1e;
  width: 100%;
}
.nome {
  text-align: center;
}

/* Mobile */
@media (max-width: 700px) {
  .container {
    padding: 20px;
    background-color: #bb0b1e;
    width: 100%;
  }
  .nome {
    text-align: center;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
