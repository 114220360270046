.qrcode {
  padding: 30px;
  height: 75vh;
}
.loading {
  width: 100px !important;
  height: 100px !important;
}
/* Mobile */
@media (max-width: 700px) {
  .qrcode {
    padding: 20px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
