.container {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
}
.texto {
    width: 90%;
    display: flex;
    justify-content: center;
}
.preco {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}

/* Mobile */
@media (max-width: 700px) {
   .container {
    padding: 20px;
   }
}
    
/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  
}

@media (prefers-color-scheme: dark) {
  
}