.maquina {
  display: flex;
  justify-content: center;
  margin: 10px;
  max-width: 400px;
}

/* Mobile */
@media (max-width: 700px) {
  .maquina {
    margin: 10px;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
