.calculadora {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}
.titulo {
  color: gray;
}
.visor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: row;
  color: #3676a1;
  font-style: normal;
  font-weight: 300;
  padding: 10px 0;
}

.visor > h4,
.visor > h2 {
  margin: 0;
}

.linha {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 6px;
  width: 100%;
}

/* Mobile */
@media (max-width: 700px) {
  .calculadora {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }
  .titulo {
    color: gray;
  }
  .visor {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: row;
    color: #3676a1;
    font-style: normal;
    font-weight: 300;
    padding: 10px 0;
  }

  .visor > h4,
  .visor > h2 {
    margin: 0;
  }

  .linha {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
    width: 100%;
  }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

@media (prefers-color-scheme: dark) {
}
