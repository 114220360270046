.botao {
    display: flex;
    flex: 1 1 auto;
    font-size: 2.0em !important;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    min-height: 50px;
}
.botao  span  svg {
    font-size: 1.5em !important;
}
/* Mobile */
@media (max-width: 700px) {
    .botao {
        display: flex;
        flex: 1 1 auto;
        font-size: 2.5em !important;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        min-height: 50px;
    }
    .botao  span  svg {
        font-size: 1.5em !important;
    }
 }
  /* Tablet and Smaller Desktop */
  @media (min-width: 701px) and (max-width: 1120px) {
  }
  
  @media (prefers-color-scheme: dark) {
  }
  
